import * as React from "react"
import SEO from "../components/seo"
import { Heading } from "../components/heading"
import Header from "../components/header"

const PrivacyPolicy: React.FunctionComponent = () => (
  <div>
    <Header />
    <SEO title="Privacy Policy" />
    <main id="privacy-policy" className="px-4 max-w-5xl mx-auto">
      <Heading level={1}>Privacy Policy</Heading>
      <p>Last updated March 10, 2021</p>

      <p>
        Thank you for choosing to be part of our community at Andricco Karoulla
        ("Company", "we", "us", "our"). We are committed to protecting your
        personal information and your right to privacy. If you have any
        questions or concerns about this privacy notice, or our practices with
        regards to your personal information, please contact us at
        andrico@calisthenicsskills.com.
      </p>

      <p>
        When you use our mobile application, as the case may be (the "App") and
        more generally, use any of our services (the "Services", which include
        the App), we appreciate that you are trusting us with your personal
        information. We take your privacy very seriously. In this privacy
        notice, we seek to explain to you in the clearest way possible what
        information we collect, how we use it and what rights you have in
        relation to it. We hope you take some time to read through it carefully,
        as it is important. If there are any terms in this privacy notice that
        you do not agree with, please discontinue use of our Services
        immediately.
      </p>

      <p>
        This privacy notice applies to all information collected through our
        Services (which, as described above, includes our App), as well as, any
        related services, sales, marketing or events.
      </p>

      <p>
        Please read this privacy notice carefully as it will help you understand
        what we do with the information that we collect.
      </p>

      <Heading level={3}>TABLE OF CONTENTS</Heading>

      <ul>
        <li>
          <a href="#1.">1. WHAT INFORMATION DO WE COLLECT?</a>
        </li>
        <li>
          <a href="#2.">2. HOW DO WE USE YOUR INFORMATION?</a>
        </li>
        <li>
          <a href="#3.">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
        </li>
        <li>
          <a href="#4.">4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a>
        </li>
        <li>
          <a href="#5.">5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a>
        </li>
        <li>
          <a href="#6.">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </li>
        <li>
          <a href="#7.">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </li>
        <li>
          <a href="#8.">8. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </li>
        <li>
          <a href="#9.">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </li>
        <li>
          <a href="#10.">
            10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </li>
        <li>
          <a href="#11.">11. DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </li>
        <li>
          <a href="#12.">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </li>
        <li>
          <a href="#13.">
            13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </li>
      </ul>

      <Heading id="1." level={3}>
        1. WHAT INFORMATION DO WE COLLECT?
      </Heading>

      <p>Information automatically collected</p>

      <p>
        In Short: Some information — such as your Internet Protocol (IP) address
        and/or browser and device characteristics — is collected automatically
        when you visit our App.
      </p>

      <p>
        We automatically collect certain information when you visit, use or
        navigate the App. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our App and other technical information. This information is primarily
        needed to maintain the security and operation of our App, and for our
        internal analytics and reporting purposes.
      </p>

      <p>The information we collect includes:</p>
      <p>
        Log and Usage Data. Log and usage data is service-related, diagnostic,
        usage and performance information our servers automatically collect when
        you access or use our App and which we record in log files. Depending on
        how you interact with us, this log data may include your IP address,
        device information, browser type and settings and information about your
        activity in the App (such as the date/time stamps associated with your
        usage, pages and files viewed, searches and other actions you take such
        as which features you use), device event information (such as system
        activity, error reports (sometimes called 'crash dumps') and hardware
        settings). Device Data. We collect device data such as information about
        your computer, phone, tablet or other device you use to access the App.
        Depending on the device used, this device data may include information
        such as your IP address (or proxy server), device and application
        identification numbers, location, browser type, hardware model Internet
        service provider and/or mobile carrier, operating system and system
        configuration information.
      </p>

      <p>Information collected through our App</p>

      <p>
        In Short: We collect information regarding your mobile device, push
        notifications, when you use our App.
      </p>

      <p>If you use our App, we also collect the following information:</p>
      <p>
        Mobile Device Data. We automatically collect device information (such as
        your mobile device ID, model and manufacturer), operating system,
        version information and system configuration information, device and
        application identification numbers, browser type and version, hardware
        model Internet service provider and/or mobile carrier, and Internet
        Protocol (IP) address (or proxy server). If you are using our App, we
        may also collect information about the phone network associated with
        your mobile device, your mobile device’s operating system or platform,
        the type of mobile device you use, your mobile device’s unique device ID
        and information about the features of our App you accessed. Push
        Notifications. We may request to send you push notifications regarding
        your account or certain features of the App. If you wish to opt-out from
        receiving these types of communications, you may turn them off in your
        device's settings. This information is primarily needed to maintain the
        security and operation of our App, for troubleshooting and for our
        internal analytics and reporting purposes.
      </p>

      <Heading id="2." level={3}>
        2. HOW DO WE USE YOUR INFORMATION?
      </Heading>

      <p>
        In Short: We process your information for purposes based on legitimate
        business interests, the fulfillment of our contract with you, compliance
        with our legal obligations, and/or your consent.
      </p>

      <p>
        We use personal information collected via our App for a variety of
        business purposes described below. We process your personal information
        for these purposes in reliance on our legitimate business interests, in
        order to enter into or perform a contract with you, with your consent,
        and/or for compliance with our legal obligations. We indicate the
        specific processing grounds we rely on next to each purpose listed
        below.
      </p>

      <p>We use the information we collect or receive:</p>
      <p>
        To send administrative information to you. We may use your personal
        information to send you product, service and new feature information
        and/or information about changes to our terms, conditions, and policies.
        To protect our Services. We may use your information as part of our
        efforts to keep our App safe and secure (for example, for fraud
        monitoring and prevention). To enforce our terms, conditions and
        policies for business purposes, to comply with legal and regulatory
        requirements or in connection with our contract. To respond to legal
        requests and prevent harm. If we receive a subpoena or other legal
        request, we may need to inspect the data we hold to determine how to
        respond.
      </p>

      <p>
        For other business purposes. We may use your information for other
        business purposes, such as data analysis, identifying usage trends,
        determining the effectiveness of our promotional campaigns and to
        evaluate and improve our App, products, marketing and your experience.
        We may use and store this information in aggregated and anonymized form
        so that it is not associated with individual end users and does not
        include personal information. We will not use identifiable personal
        information without your consent.
      </p>

      <Heading id="3." level={3}>
        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
      </Heading>

      <p>
        In Short: We only share information with your consent, to comply with
        laws, to provide you with services, to protect your rights, or to
        fulfill business obligations.
      </p>

      <p>
        We may process or share your data that we hold based on the following
        legal basis:
      </p>
      <p>
        Consent: We may process your data if you have given us specific consent
        to use your personal information for a specific purpose. Legitimate
        Interests: We may process your data when it is reasonably necessary to
        achieve our legitimate business interests. Performance of a Contract:
        Where we have entered into a contract with you, we may process your
        personal information to fulfill the terms of our contract. Legal
        Obligations: We may disclose your information where we are legally
        required to do so in order to comply with applicable law, governmental
        requests, a judicial proceeding, court order, or legal process, such as
        in response to a court order or a subpoena (including in response to
        public authorities to meet national security or law enforcement
        requirements). Vital Interests: We may disclose your information where
        we believe it is necessary to investigate, prevent, or take action
        regarding potential violations of our policies, suspected fraud,
        situations involving potential threats to the safety of any person and
        illegal activities, or as evidence in litigation in which we are
        involved. More specifically, we may need to process your data or share
        your personal information in the following situations: Business
        Transfers. We may share or transfer your information in connection with,
        or during negotiations of, any merger, sale of company assets,
        financing, or acquisition of all or a portion of our business to another
        company.
      </p>

      <Heading id="4." level={3}>
        4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
      </Heading>

      <p>
        In Short: We may transfer, store, and process your information in
        countries other than your own.
      </p>

      <p>
        Our servers are located in. If you are accessing our App from outside,
        please be aware that your information may be transferred to, stored, and
        processed by us in our facilities and by those third parties with whom
        we may share your personal information (see "WILL YOUR INFORMATION BE
        SHARED WITH ANYONE?" above), in and other countries.
      </p>
      <p>
        If you are a resident in the European Economic Area, then these
        countries may not necessarily have data protection laws or other similar
        laws as comprehensive as those in your country. We will however take all
        necessary measures to protect your personal information in accordance
        with this privacy notice and applicable law.
      </p>

      <Heading id="5." level={3}>
        5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
      </Heading>

      <p>
        In Short: We are not responsible for the safety of any information that
        you share with third-party providers who advertise, but are not
        affiliated with, our Website.
      </p>

      <p>
        The App may contain advertisements from third parties that are not
        affiliated with us and which may link to other websites, online services
        or mobile applications. We cannot guarantee the safety and privacy of
        data you provide to any third parties. Any data collected by third
        parties is not covered by this privacy notice. We are not responsible
        for the content or privacy and security practices and policies of any
        third parties, including other websites, services or applications that
        may be linked to or from the App. You should review the policies of such
        third parties and contact them directly to respond to your questions.
      </p>

      <Heading id="6." level={3}>
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
      </Heading>

      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law.
      </p>

      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than __________.
      </p>

      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <Heading id="7." level={3}>
        7. HOW DO WE KEEP YOUR INFORMATION SAFE?
      </Heading>

      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
      </p>
      <p>
        We have implemented appropriate technical and organizational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our App is at your own risk. You should only
        access the App within a secure environment.
      </p>

      <Heading id="8." level={3}>
        8. WHAT ARE YOUR PRIVACY RIGHTS?
      </Heading>

      <p>
        In Short: You may review, change, or terminate your account at any time.
      </p>

      <p>
        If you are a resident in the European Economic Area and you believe we
        are unlawfully processing your personal information, you also have the
        right to complain to your local data protection supervisory authority.
        You can find their contact details here:
        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
      </p>

      <p>
        If you are a resident in Switzerland, the contact details for the data
        protection authorities are available here:
        https://www.edoeb.admin.ch/edoeb/en/home.html.
      </p>

      <Heading id="9." level={3}>
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
      </Heading>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>

      <Heading id="10." level={3}>
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </Heading>

      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
      </p>

      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the App, you have the right to request removal
        of unwanted data that you publicly post on the App. To request removal
        of such data, please contact us using the contact information provided
        below, and include the email address associated with your account and a
        statement that you reside in California. We will make sure the data is
        not publicly displayed on the App, but please be aware that the data may
        not be completely or comprehensively removed from all our systems (e.g.
        backups, etc.).
      </p>

      <Heading id="11." level={3}>
        11. DO WE MAKE UPDATES TO THIS NOTICE?
      </Heading>

      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
      </p>

      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <Heading id="12." level={3}>
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </Heading>

      <p>
        If you have questions or comments about this notice, you may email us at
        andrico@calisthenicsskills.com or by post to:
      </p>

      <p>Andricco Karoulla</p>

      <p>Flat 203 20 Hawthorne Crescent</p>
      <p>London, Greater London SE10 9GW</p>
      <p>United Kingdom</p>

      <Heading id="13." level={3}>
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </Heading>

      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to
        review, update, or delete your personal information, please submit a
        request form by clicking here. We will respond to your request within 30
        days.
      </p>
    </main>
  </div>
)

export default PrivacyPolicy
